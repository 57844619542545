import { Box, CardContent, Checkbox, CircularProgress, FormControlLabel } from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { Alert } from "@material-ui/lab";
import { MouseEvent } from "react";

import { EOnboardingSteps } from "data/users/types";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import fillRoute from "utils/routes";

import StepBar from "ui/StepBar";

import { stepsLength } from "../../../config";
import { Desc, MainContainer, MainContent, StepContainer, StyledButton, TitleHeader } from "../../../shared.styled";
import { IExperienceElem } from "../index";
import {
	CardBottomInfo,
	CardLabel,
	CardsContainer,
	CardTitle,
	CheckboxContainer,
	CheckIconContainer,
	DetailsButton,
	SmallerColumn,
	StyledCard,
	StyledCardActionArea,
	StyledCardMedia,
	StyledLink,
} from "../shared.styled";
import ExperienceDetailsModal from "./ExperienceDetailsModal";

export interface ModalProps {
	openModal: boolean;
	handleCloseModal(): void;
}

export interface ExperiencesLayoutProps extends ModalProps {
	experiences: IExperienceElem[] | null | undefined;
	experienceDetails: IExperienceElem | null;
	isCancellationPolicyAccepted: boolean;
	selectOnlyBinipools: boolean;
	isNextButtonDisabled(): boolean;
	handleNextButtonClick(event: MouseEvent<HTMLButtonElement>): void;
	handleSeeDetailsClick(event: MouseEvent, experienceId: string): void;
	handleExperienceSelectClick(index: number): void;
	setIsCancellationPolicyAccepted(isAccepted: boolean): void;
}

const ExperiencesLayout = ({
	experiences,
	handleSeeDetailsClick,
	openModal,
	handleNextButtonClick,
	handleCloseModal,
	handleExperienceSelectClick,
	isNextButtonDisabled,
	isCancellationPolicyAccepted,
	setIsCancellationPolicyAccepted,
	experienceDetails,
	selectOnlyBinipools,
}: ExperiencesLayoutProps) => {
	const { t } = useTranslation();

	const renderList = () => {
		if (experiences === undefined) {
			return (
				<Alert severity="info">
					{t("ONBOARDING.BINIPOOL.WARNING.SELECT_CITY")}{" "}
					<StyledLink
						to={fillRoute(paths.ONBOARDING, { step: EOnboardingSteps.ShortBio, search: { type: "binipool" } })}
					>
						{t("COMMON.LINK")}
					</StyledLink>
				</Alert>
			);
		}

		if (experiences?.length === 0) {
			return (
				<Alert severity="info">
					{t("ONBOARDING.BINIPOOL.WARNING.NO_EXPERIENCES")}{" "}
					<StyledLink
						to={fillRoute(paths.ONBOARDING, { step: EOnboardingSteps.ShortBio, search: { type: "binipool" } })}
					>
						{t("COMMON.LINK")}
					</StyledLink>
				</Alert>
			);
		}

		if (!!experiences?.length) {
			return (
				<CardsContainer>
					{experiences?.map((experience: IExperienceElem, index) => (
						<StyledCard
							$active={!!experience.isSelected}
							id={`experience_card_link_${experience.id}`}
							key={experience.id}
							onClick={() => handleExperienceSelectClick(index)}
						>
							<StyledCardActionArea>
								<StyledCardMedia image={experience.photo} title={experience.title}>
									<CheckIconContainer active={!!experience.isSelected}>
										<CheckCircleOutlineIcon />
									</CheckIconContainer>
								</StyledCardMedia>

								<CardContent>
									<CardTitle>{experience.title}</CardTitle>

									<CardLabel>
										{experience.city} &nbsp;|&nbsp; {experience.duration_time} {t("ONBOARDING.BINIPOOL.HOURS")}
									</CardLabel>

									<CardBottomInfo>
										<DetailsButton onClick={event => handleSeeDetailsClick(event, experience.id)}>
											{t("ONBOARDING.BINIPOOL.SEE_DETAILS")}
										</DetailsButton>
									</CardBottomInfo>
								</CardContent>
							</StyledCardActionArea>
						</StyledCard>
					))}

					{experienceDetails && (
						<ExperienceDetailsModal
							openModal={openModal}
							handleCloseModal={handleCloseModal}
							experienceDetails={experienceDetails}
						/>
					)}
				</CardsContainer>
			);
		}

		return (
			<Box height={200} display="flex" justifyContent="center" alignItems="center">
				<CircularProgress color="secondary" />
			</Box>
		);
	};

	return (
		<MainContainer>
			<MainContent maxWidth={645}>
				<SmallerColumn>
					<TitleHeader>{t("ONBOARDING.BINIPOOL.HEADER")}</TitleHeader>

					<StepContainer>
						<StepBar
							activeStep={EOnboardingSteps.Binipools}
							stepsAmount={selectOnlyBinipools ? stepsLength - 1 : stepsLength}
						/>
					</StepContainer>

					<Desc>{t("ONBOARDING.BINIPOOL.DESC")}</Desc>
					<Desc>{t("ONBOARDING.BINIPOOL.DESC_LATER")}</Desc>
				</SmallerColumn>

				{renderList()}

				<SmallerColumn>
					<CheckboxContainer required>
						<FormControlLabel
							value="end"
							control={
								<Checkbox
									id="experiences_accept_policy_checkbox"
									color="primary"
									checked={isCancellationPolicyAccepted}
									onChange={(event, checked) => setIsCancellationPolicyAccepted(checked)}
								/>
							}
							label={
								<small>
									{t("ONBOARDING.BINIPOOL.CANCELLATION_POLICY_1")}
									<StyledLink to={paths.HELP_CANCELLATION_POLICY} target="_blank">
										{t("ONBOARDING.BINIPOOL.CANCELLATION_POLICY_2")}
									</StyledLink>
									{t("ONBOARDING.BINIPOOL.CANCELLATION_POLICY_3")}
								</small>
							}
							labelPlacement="end"
						/>
					</CheckboxContainer>

					<StyledButton
						id="experiences_button_next_step"
						onClick={handleNextButtonClick}
						disabled={isNextButtonDisabled()}
						variant="contained"
						color="primary"
					>
						{t(selectOnlyBinipools ? "ONBOARDING.SAVE_AND_SEND_TO_REVIEW" : "ONBOARDING.NEXT_BUTTON")}
					</StyledButton>
				</SmallerColumn>
			</MainContent>
		</MainContainer>
	);
};

export default ExperiencesLayout;
