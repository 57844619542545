import {
	ECancellationReason,
	EDeliveryMethod,
	EHostingRequestContactStatus,
	ELocalhostPostStatus,
	ELocalhostPreStatus,
	EStatusHostingRequest,
	ETravellerPostStatus,
	IBooking,
	IBookingIId,
	IEarningsEstimateForUsers,
	IPendingRequest,
	IPostReimburseLocalhostCosts,
	ISuperbookingIId,
	TSuperbookingQAIId,
} from "data/backoffice/bookings/types";

import { AmplifyAPIRequest } from "../aws/types";
import API, { APISingleData } from "./index";
import { TGetSearchSuperbookingQAParams } from "./types/bookings";
import { createCRUDAPI, generateEntityEndpoint } from "./utils/createCRUDAPI";

export type IBookingsDTO = any;

const internalNamespace = "internal";
const apiName = "/bookings";
const apiNameSuperbookings = "/superbookings";
const apiNameSuperbookingQA = "/superbooking-qa";

const fromDTO = dataDTO => dataDTO;
const toDTO = data => data;

const bookingsService = {
	...createCRUDAPI<IBookingsDTO>(apiName, internalNamespace, fromDTO, toDTO),
	//
	//
	//
	fetchSuperBookingsBackoffice: (queryParams): Promise<{ count: number; data: ISuperbookingIId[] }> =>
		APISingleData.get(internalNamespace, apiNameSuperbookings, {
			queryStringParameters: queryParams,
		}),
	getSuperbookingById: (superbookingId: string): Promise<ISuperbookingIId> =>
		API.get(internalNamespace, `${apiNameSuperbookings}/${superbookingId}`),
	patchSuperbooking: (
		entityId: string,
		data: { localhost_id?: null; language?: string; date_time?: string; exp_version?: number },
	): AmplifyAPIRequest<ISuperbookingIId> =>
		API.patch(internalNamespace, generateEntityEndpoint(apiNameSuperbookings, entityId), {
			body: data,
		}),
	//
	//
	//
	fetchBookings: (queryParams): Promise<{ count: number; data: IBookingIId[] }> =>
		APISingleData.get(internalNamespace, apiName, {
			queryStringParameters: queryParams,
		}),
	getBooking: (bookingId: string, withGuestCredentials?: boolean) =>
		APISingleData.get<{ data: IBookingIId }>(
			internalNamespace,
			`${apiName}/${bookingId}`,
			undefined,
			undefined,
			withGuestCredentials,
		),
	getBookingReceipt: (bookingId: string) =>
		APISingleData.get<{ url: string }>(internalNamespace, `${apiName}/${bookingId}/receipt`),
	postBooking: (
		data: Partial<IBooking> & {
			created_on?: string;
			promo_code?: string;
			gift_card?: string;
			affiliate_code?: string | null;
		},
	): AmplifyAPIRequest<IBookingIId> =>
		APISingleData.post(internalNamespace, apiName, {
			body: data,
		}),
	patchUpdateBooking: (
		bookingId: string,
		data: Partial<Pick<IBooking, "comments" | "partner_resell_price" | "paid_travelers" | "tickets">> &
			Partial<IBooking["traveler_details"]>,
	) =>
		APISingleData.patch<IBookingIId>(internalNamespace, `${apiName}/${bookingId}`, {
			body: data,
		}),
	postCancelBooking: (bookingId: string, reason?: ECancellationReason, withGuestCredentials?: boolean) =>
		API.post<undefined>(
			internalNamespace,
			`${apiName}/${bookingId}/cancel`,
			{
				body: {
					reason,
				},
			},
			undefined,
			withGuestCredentials,
		),
	postRefundBooking: (bookingId: string, params?: { amount?: number }) =>
		API.post<IBookingIId>(internalNamespace, `${apiName}/${bookingId}/refund`, { body: params || {} }),
	moveBooking: (bookingId: string, data: { superbooking_id?: string | null }): Promise<any> =>
		APISingleData.post(internalNamespace, `${apiName}/${bookingId}/move`, {
			body: data,
		}),
	//
	//
	//
	getHostingNotifications: queryParams =>
		APISingleData.get<{ data: IPendingRequest[]; count: number }>(internalNamespace, "/hosting-notifications", {
			queryStringParameters: queryParams,
		}),
	getHostingNotificationDetails: (notificationId: string) =>
		APISingleData.get<IPendingRequest>(internalNamespace, `/hosting-notifications/${notificationId}`),
	deleteCancelHostingNotification: (notificationId: string) =>
		APISingleData.del<{ message: "canceled" }>(internalNamespace, `/hosting-notifications/${notificationId}`),
	patchPartialUpdateHostingNotification: (
		notificationId: string,
		data: {
			status?: EStatusHostingRequest;
			seen?: true;
			contact_status?: EHostingRequestContactStatus;
			rejection_reason?: string;
			rejection_comment?: string;
		},
	) =>
		API.patch<IPendingRequest>(internalNamespace, `/hosting-notifications/${notificationId}`, {
			body: data,
		}),
	//
	//
	//
	postSuperbookingNotifications: (
		superbookingId: string,
		data: { notifications: { localhost_id: string; proposed_earnings?: number; delivery_method?: EDeliveryMethod }[] },
	) =>
		APISingleData.post<{ message: string }>(
			internalNamespace,
			`${apiNameSuperbookings}/${superbookingId}/hosting-notifications`,
			{
				body: data,
			},
		),
	//
	//
	//
	postEstimateLocalhostEarnings: (superbookingId: string, localhostIds: string[]): Promise<IEarningsEstimateForUsers> =>
		APISingleData.post(internalNamespace, `${apiNameSuperbookings}/${superbookingId}/estimate-localhost-earnings`, {
			body: { localhost_ids: localhostIds },
		}),
	postReimburseLocalhostCosts: (superbookingId: string, data: IPostReimburseLocalhostCosts): Promise<any> =>
		API.post(internalNamespace, `${apiNameSuperbookings}/${superbookingId}/reimburse-localhost-costs`, {
			body: data,
		}),
	//
	//
	//
	postHeldCode: (bid: string, heldCode: string) => API.post(internalNamespace, `${apiName}/${bid}/confirm/${heldCode}`),
	//
	//
	//
	getSearchSuperbookingQA: (queryParams: TGetSearchSuperbookingQAParams) =>
		APISingleData.get<{ count: number; data: TSuperbookingQAIId[] }>(internalNamespace, apiNameSuperbookingQA, {
			queryStringParameters: queryParams,
		}),
	getSuperbookingQA: (superbookingId: string) =>
		APISingleData.get<TSuperbookingQAIId>(internalNamespace, `${apiNameSuperbookingQA}/${superbookingId}`),
	patchSetLocalhostPreCheck: (superbookingId: string, data: { note?: string; status: ELocalhostPreStatus }) =>
		APISingleData.patch<TSuperbookingQAIId>(
			internalNamespace,
			`${apiNameSuperbookingQA}/${superbookingId}/localhost-pre-check`,
			{
				body: data,
			},
		),
	patchSetLocalhostPostCheck: (superbookingId: string, data: { note?: string; status: ELocalhostPostStatus }) =>
		APISingleData.patch<TSuperbookingQAIId>(
			internalNamespace,
			`${apiNameSuperbookingQA}/${superbookingId}/localhost-post-check`,
			{
				body: data,
			},
		),
	patchSetTravelerPostCheck: (
		superbookingId: string,
		bookingId: string,
		data: { note?: string; status: ETravellerPostStatus },
	) =>
		APISingleData.patch<TSuperbookingQAIId>(
			internalNamespace,
			`${apiNameSuperbookingQA}/${superbookingId}/traveler-post-check/${bookingId}`,
			{
				body: data,
			},
		),

	postUnassignLocalhost: (
		superbookingId: string,
		data: { requested_by_localhost: boolean },
	): AmplifyAPIRequest<ISuperbookingIId> =>
		API.post(internalNamespace, `${apiNameSuperbookings}/${superbookingId}/unassign-localhost`, {
			body: data,
		}),
};

export default bookingsService;
