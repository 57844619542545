import { BaseTextFieldProps, InputProps, TextFieldProps, Typography } from "@material-ui/core";
import { InputBaseProps } from "@material-ui/core/InputBase/InputBase";
import { InputLabelProps } from "@material-ui/core/InputLabel/InputLabel";
import { Field } from "formik";
import { isUndefined, omit } from "lodash-es";
import { ReactNode } from "react";
import styled from "styled-components";

import TextFieldBox, { TextFieldBoxProps } from "components/forms/TextFieldBox";

const InlineLabel = styled(Typography)`
	font-size: 0.875rem;
	font-weight: 500;
	margin-right: 10px;
`;

interface IInputV2 extends BaseTextFieldProps {
	name: string;
	shrink?: boolean;
	uppercase?: boolean;
	toNumberValue?: boolean;
	startAdornment?: ReactNode;
	endAdornment?: ReactNode;
	inlineLabel?: string;
	disableUnderline?: InputProps["disableUnderline"];
	inputProps?: InputBaseProps;
	labelProps?: InputLabelProps;
}

const InputField = ({
	name,
	variant,
	uppercase,
	toNumberValue,
	startAdornment,
	endAdornment,
	inlineLabel,
	inputProps,
	disableUnderline,
	shrink,
	labelProps,
	...rest
}: Omit<IInputV2 & TextFieldBoxProps, "value">) => (
	<Field name={name}>
		{({ field, form: { setFieldValue }, meta }) => {
			const hasError = meta.touched && !!meta.error;

			const localInputProps: TextFieldProps["InputProps"] = {
				endAdornment: endAdornment || null,
				startAdornment: startAdornment || null,
				inputProps: { ...inputProps },
				disableUnderline,
			};

			if (isUndefined(localInputProps.disableUnderline)) {
				delete localInputProps.disableUnderline;
			}

			const onChange = (value: string) =>
				setFieldValue(field.name, uppercase ? value.toUpperCase() : toNumberValue ? Number(value) : value);

			const props = {
				...omit(field, ["value", "onChange"]),
				...rest,
				value: field.value || (toNumberValue ? 0 : ""),
			};

			return (
				<>
					{inlineLabel && <InlineLabel>{inlineLabel}</InlineLabel>}

					<TextFieldBox
						onChange={event => onChange(event.target.value)}
						variant={variant || "outlined"}
						error={hasError}
						helperText={hasError && meta.error}
						InputProps={localInputProps}
						InputLabelProps={{ shrink, ...labelProps }}
						{...(props.type === "number" && { onWheel: e => e.target instanceof HTMLElement && e.target.blur() })}
						{...props}
					/>
				</>
			);
		}}
	</Field>
);

export default InputField;
