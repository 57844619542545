import { Box, CircularProgress } from "@material-ui/core";
import { useCallback, useMemo, useState } from "react";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import styled from "styled-components";

import { EOnboardingSteps, EUserOnboardingMeetingStatus, EUserStatus } from "data/users/types";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import { isDevelopment } from "utils/environments";

import StepBar from "ui/StepBar";

import colors from "styles/colors";
import { StyledExternalLink } from "styles/common";
import { sizes } from "styles/media";

import { linkToCalendly, linkToCalendlyDevelop, stepsLength } from "../../config";
import {
	Alignment,
	MainContainer,
	MainContent,
	spaceDefault,
	StepContainer,
	StyledButton,
	TitleHeader,
} from "../../shared.styled";
import { ISharedOnboardingProps } from "../../types";
import FinalModal from "../FinalModal";

const CalendlyContainer = styled.div`
	margin-bottom: ${spaceDefault * 2}px;

	.calendly-inline-widget {
		min-width: initial !important;
	}
`;

const CalendlyText = styled.p`
	padding-bottom: 10px;
	font-size: 14px;
	text-align: center;
	line-height: 24px;
	color: #666;
	width: 80%;
	margin: auto;
`;

const pageSettings = {
	hideEventTypeDetails: true,
	primaryColor: colors.sunShade,
	hideGdprBanner: true,
};

export const CalendlyStep = ({
	updateProfileData,
	profileData,
	isBusy,
	typeBinipool,
	email,
	phone,
}: ISharedOnboardingProps & { email?: string; phone?: string }) => {
	const { t, withRaw } = useTranslation();

	const [scheduled, setScheduled] = useState<boolean>(false);
	const [finalModal, setFinalModal] = useState<boolean>(false);

	const handleCloseModal = useCallback(() => setFinalModal(false), []);

	const prefillData = useMemo(() => {
		const userName =
			profileData?.first_name && profileData?.last_name ? `${profileData.first_name} ${profileData.last_name}` : null;
		const city = profileData?.cities?.length ? profileData.cities.join(", ") : null;

		return {
			email,
			...(userName && { name: userName }),
			...((city || !!phone) && {
				customAnswers: {
					...(city && { a1: city }),
					...(!!phone && { a2: phone }),
				},
			}),
		};
	}, [email, phone, profileData?.first_name, profileData?.last_name, profileData?.cities]);

	useCalendlyEventListener({
		onEventScheduled: async e => {
			const apiCall = await updateProfileData({
				status: EUserStatus.AWAITING_REVIEW,
				meeting: {
					meeting_id: e.data.payload.invitee.uri.split("/").pop() || "",
					meeting_status: EUserOnboardingMeetingStatus.SCHEDULED,
				},
			});

			if (apiCall === true) {
				setScheduled(true);

				setFinalModal(true);
			}
		},
	});

	return (
		<MainContainer>
			<Alignment>
				<MainContent flex={1} maxWidth={sizes.largeDesktop}>
					<Box maxWidth="460px" width="100%" margin="0 auto">
						<TitleHeader>{withRaw("ONBOARDING.CALENDLY.HEADER", null)}</TitleHeader>

						<StepContainer>
							<StepBar activeStep={EOnboardingSteps.Calendly} stepsAmount={stepsLength} />
						</StepContainer>
					</Box>
					<CalendlyText>{t("ONBOARDING.CALENDLY.INFO_1")}</CalendlyText>
					<CalendlyText>{t("ONBOARDING.CALENDLY.INFO_2")}</CalendlyText>
					<CalendlyContainer>
						<InlineWidget
							url={isDevelopment ? linkToCalendlyDevelop : linkToCalendly}
							pageSettings={pageSettings}
							prefill={prefillData}
						/>
					</CalendlyContainer>

					{scheduled ? (
						<Box margin="0 auto">
							{/* Normal link to reset app state! */}
							<StyledExternalLink href={paths.DASHBOARD}>
								<StyledButton id="go_to_dashboard_button" variant="contained" color="primary">
									{t("ONBOARDING.CALENDLY.GO_TO_DASHBOARD_BUTTON")}
								</StyledButton>
							</StyledExternalLink>
						</Box>
					) : (
						isBusy && (
							<Box height={50} display="flex" justifyContent="center" alignItems="center">
								<CircularProgress color="secondary" />
							</Box>
						)
					)}
				</MainContent>
			</Alignment>

			<FinalModal handleCloseModal={handleCloseModal} openModal={finalModal} binipool={typeBinipool} />
		</MainContainer>
	);
};
